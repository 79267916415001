import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MealDayPicker } from '../components/MealDayPicker';
import { RECIPE_LINK } from '../links';
import { highlightedTitle } from '../styles/typography';

export const Onboarding = ({ }) => {
    const navigate = useNavigate();
    return <div style={{ backgroundColor: '#E3E2E0', textAlign: 'center' }}>
        <div style={{ backgroundColor: '#E3E2E0', textAlign: 'center', width: '90%', margin: 'auto', padding: '10px auto 50px auto', maxWidth: '900px' }}>
            <h1 style={highlightedTitle}>Onboarding</h1>
            <MealDayPicker done={() => navigate(RECIPE_LINK)} />
        </div>
    </div>
}
