
export const primaryButton = {
    backgroundColor: '#C9882D',
    color: '#302C2F',
    borderRadius: '10px',
    fontFamily: 'Poppins; san-serif',
    fontWeight: 'bold',
    fontSize: 18,
    fontWeight: 700,
}

export const secondaryButton = {
    backgroundColor: '#E3E2E0',
    color: '#302C2F',
    borderRadius: '10px',
    fontFamily: 'Poppins; san-serif',
    fontWeight: 'bold',
    fontSize: 18,
    fontWeight: 700,
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#BEB3A9',
}
