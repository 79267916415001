import { atom } from 'recoil';

export const userState = atom({
    key: 'user',
    default: "",
});

export const settingsState = atom({
    key: 'settings',
    default: {
        restaurantsWeekly: 2,
        dayOptions: [
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: false,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: true,
                    restaurant: false,
                }
            },
            {
                breakfast: {
                    generate: true,
                    restaurant: false,
                }, lunch: {
                    generate: true,
                    restaurant: false,
                }, dinner: {
                    generate: false,
                    restaurant: false,
                }
            },
        ]
    },
});