import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, CircularProgress, FormControl, FormLabel, Input, MenuItem, Modal, Select, Slider, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import { db, storage } from './App';
import { MealCard } from './pages/Plan';
import { userState } from './state';
import { primaryButton, secondaryButton } from './styles/buttons';
import './styles/buttons.css';
import { highlightedTitle } from './styles/typography';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import { RECIPE_EDIT_LINK, RECIPE_ADD_LINK, RECIPE_LINK } from './links';


export const RecipeEditor = () => {
    const user = useRecoilValue(userState);
    const navigate = useNavigate()
    const location = useLocation()
    const [typeSelect, setTypeSelect] = React.useState('Breakfast');
    const [proteinSelect, setProteinSelect] = React.useState('Beef');
    const [name, setName] = React.useState('');
    const [blob, setBlob] = React.useState(undefined);
    const [selectedFile, setSelectedFile] = React.useState(undefined);
    const [timeToPrepare, setTimeToPrepare] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [frequency, setFrequency] = React.useState(2);
    const [ingredients, setIngredients] = React.useState('');
    const [totalTime, setTotalTime] = React.useState('');
    const [servings, setServings] = React.useState(1);
    const [errors, setErrors] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState(false);
    const [meal, setMeal] = React.useState({})

    React.useEffect(() => {
        let _meal;
        if(location.state?.currentRecipe?.id){
         _meal = location.state.currentRecipe
        }
        if (_meal) {
            console.log(_meal)
            setName(_meal.data.name)
            setNotes(_meal.data.notes)
            setProteinSelect(proteinMap[_meal.data.protein])
            setTimeToPrepare(_meal.data.timeToPrepare)
            setTypeSelect(typeMap[_meal.data.type])
            {
                _meal.data.ingredients && setIngredients([_meal.data.ingredients])
            _meal.data.totalTime && setTotalTime(_meal.data.totalTime)
            _meal.data.servings && setServings(_meal.data.servings)
        }
            setFrequency(_meal.data.frequency * 5)
            setMeal(_meal)
        }
    }, [])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const changeFile = (input) => {
        const file = input.files[0];
        setSelectedFile(file.name)
        const reader = new FileReader();
        reader.onload = async function (e) {
            let loadedBlob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
            setBlob(loadedBlob)
        };
        reader.readAsArrayBuffer(file);
    }

    const validation = () => {
        let valid = true;
        let x = {};
        if (!name) {
            valid = false;
            x['name'] = "Must provide name"
        }

        if (!valid) {
            setErrors(x)
            document.getElementById('recipename').scrollIntoView({ behavior: "smooth", block: "center" });
        }

        return valid;
    }

    const updateRecipe = async (insert) => {
        if (!validation()) {
            return;
        }


        const recipe = {
            name,
            type: typeSelect.toLowerCase(),
            protein: proteinSelect.toLowerCase(),
            timeToPrepare,
            totalTime,
            ingredients,
            servings,
            notes,
            frequency: frequency / 5,
        }

        console.log(recipe);

        if (blob) {
            const uuid = v4();
            const storageRef = ref(storage, `users/${user.uid}/${uuid}`);
            await uploadBytes(storageRef, blob)
            recipe.imageUuid = uuid;
        } else if (!insert && meal && meal.imageUuid) {
            recipe.imageUuid = meal.data.imageUuid;
        }


        if (!location.state?.isEdit) {
            
            await addDoc(collection(db, 'users/' + user.uid + '/recipes'), recipe);
        } else {
            await updateDoc(doc(db, 'users/' + user.uid + '/recipes/' + meal.id), recipe);
        }
        navigate(RECIPE_LINK)
    }

    const deleteRecipe = async () => {
        //TODO delete image
        deleteDoc(doc(db, 'users/' + user.uid + '/recipes/' + meal.id));
        navigate(RECIPE_LINK)
    }

    const getTitle = ()=>{
        if(location.state?.isEdit){
            return "Edit Dish"
        }else{
            return "Add Dish"
        }
    }
    const typeMap = {
        "dinner": "Dinner",
        "lunch": "Lunch",
        "breakfast": "Breakfast",
    }

    const proteinMap = {
        "beef": "Beef",
        "chicken": "Chicken",
        "pork": "Pork",
        "turkey": "Turkey",
        "plant-based": "Plant-Based",
        "other": "Other",
        "none": "None",
    }

    const frequencyMap = {
        0: 'Very Rare',
        1: 'Uncommon',
        2: 'Average',
        3: 'Often',
        4: 'Very Often',
    }

    

    return <div style={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '900px', textAlign: 'center', margin: '0px auto' }}>
        <Button style={{ ...secondaryButton, width: '100px', margin: '80px 0px 0px 0px', }} onClick={() => {
            navigate(RECIPE_LINK)
        }}>
            <ArrowBackIosIcon />
            Back
        </Button>
        <p style={{ ...highlightedTitle, margin: '30px auto' }} >{getTitle()}</p>
        <FormControl id="recipename" margin='dense' error={errors.name}><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Recipe Name</FormLabel>
            <Input placeholder="Recipe Name" value={name} onChange={(e) => setName(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <div style={{ height: '10px' }}></div>
        <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={(e, a) => changeFile(e.target)}
        />
        {meal?.data?.url && <img src={meal.data.url} />}

        <label htmlFor="raised-button-file">
            <Button variant="raised" component="span" style={{ ...secondaryButton, width: '100%', height: 100, display: 'flex', flexDirection: 'column' }}>
                Upload Image
                {selectedFile ? <p style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Poppins' }}>{selectedFile}</p> : undefined}
            </Button>
        </label>
        <FormControl>
            <FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Type</FormLabel>
            <Select value={typeSelect} sx={{ color: '#302F31' }} onChange={(e) => setTypeSelect(e.target.value)}>
                <MenuItem value="Breakfast">Breakfast</MenuItem>
                <MenuItem value="Lunch">Lunch</MenuItem>
                <MenuItem value="Dinner">Dinner</MenuItem>
            </Select>
        </FormControl>
        <FormControl>
            <FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Protein Base</FormLabel>
            <Select value={proteinSelect} sx={{ color: '#302F31' }} onChange={(e) => setProteinSelect(e.target.value)}>
                <MenuItem value="Beef">Beef</MenuItem>
                <MenuItem value="Chicken">Chicken</MenuItem>
                <MenuItem value="Pork">Pork</MenuItem>
                <MenuItem value="Turkey">Turkey</MenuItem>
                <MenuItem value="Plant-Based">Plant-Based</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="None">None</MenuItem>
            </Select>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Prep Time</FormLabel>
            <Input placeholder="Time to Prepare" value={timeToPrepare} onChange={(e) => setTimeToPrepare(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Total Time</FormLabel>
            <Input placeholder="Total Time" value={totalTime} onChange={(e) => setTotalTime(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Ingredients List</FormLabel>
            <Input placeholder="Ingredients List" value={ingredients} onChange={(e) => setIngredients(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}># of servings</FormLabel>
            <Input placeholder="# of servings" value={servings} onChange={(e) => setServings(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Notes</FormLabel>
            <Input placeholder="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} sx={{ border: '#BEB3A9 2px solid', height: '45px' }}></Input>
        </FormControl>
        <FormControl margin='dense'><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Frequency: {frequencyMap[frequency]}</FormLabel>
            <Slider max={4} min={0} marks step={1} color='primary' onChangeCommitted={(a, v) => setFrequency(v)} value={frequency} />
        </FormControl>
        <div style={{ height: '40px' }}></div>
        <Button style={primaryButton} onClick={() => updateRecipe(!meal)}>Done</Button>
        {meal ? <Button style={{ ...secondaryButton, margin: '30px 0px' }} onClick={() => setModalOpen(true)}>Delete</Button> : undefined}
        <div style={{ height: '50px' }}></div>
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ maxWidth: 700, width: '90%', margin: 'auto', top: '50%', msTransform: 'translate(0%, -50%)', transform: 'translate(0%, -50%)', position: 'relative', backgroundColor: '#BEB3A9', textAlign: 'center', borderRadius: '10px', }}>
                <Typography id="modal-modal-title" fontSize="36px" component="h1" fontWeight="500" fontFamily="Poppins">
                    Warning!
                </Typography>
                <br />
                <Typography id="modal-modal-title" fontSize="18px" component="h2" fontFamily="Poppins">
                    Are you sure you want to delete this recipe?
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: '30px auto 30px auto', padding: '30px 0px' }}>
                    <Button style={{ ...secondaryButton, minWidth: '150px' }} onClick={() => setModalOpen(false)}>Cancel</Button>
                    <Button style={{ ...primaryButton, minWidth: '150px' }} onClick={() => deleteRecipe()}>Delete</Button>
                </div>
            </Box>
        </Modal>
    </div>
}

export const Recipes = () => {
    const [user, setUser] = useRecoilState(userState);
    const navigate = useNavigate()
    const location = useLocation()
    const [recipes, setRecipes] = React.useState(undefined);
    const [typeChosen, setTypeChosen] = React.useState('breakfast');
    const [isCreatingRecipe, setIsCreatingRecipe] = React.useState(false);
    const [currentRecipe, setCurrentRecipe] = React.useState(undefined);
    
    React.useEffect(() => {
        getRecipes();
    }, [typeChosen])


    const startRecipe = () => {
        setIsCreatingRecipe(true);
    }

    const getRecipes = async () => {
        let latestPlanQ = query(collection(db, '/users/' + user.uid + '/recipes'), where('type', '==', typeChosen))
        let newRecipes = [];
        let recipeSnapshot = await getDocs(latestPlanQ);
        recipeSnapshot.forEach(async (doc) => {
            newRecipes.push({ data: doc.data(), id: doc.id })
        });

        for (let recipe of newRecipes) {
            if (recipe.data.imageUuid) {
                recipe.data.url = await getDownloadURL(ref(storage, `users/${user.uid}/${recipe.data.imageUuid}`))
            }
        }
        setRecipes(newRecipes)
    }

    return <div style={{ backgroundColor: '#E3E2E0', minHeight: '100vh' }}>
        {!isCreatingRecipe ? <div style={{ textAlign: 'center' }}>
            <h1 style={highlightedTitle}>
                Dishes
            </h1>
        </div> : undefined}
        
        {!isCreatingRecipe ? <>
            <div style={{ width: '90%', maxWidth: '900px', margin: '0px auto 20px auto' }}>
                <Tabs variant="fullWidth" value={typeChosen} onChange={(e, v) => setTypeChosen(v)} aria-label="dish type tabs" >
                    <Tab label="Breakfast" value="breakfast" />
                    <Tab label="Lunch" value="lunch" />
                    <Tab label="Dinner" value="dinner" />
                </Tabs>
            </div>
            <div style={{ width: '90%', maxWidth: '900px', margin: 'auto', textAlign: 'center' }}>
                {recipes && recipes.length === 0 ? <>
                    <p style={{ fontFamily: 'Poppins' }}>No Recipes of this kind yet! <br />Start by adding a Recipe</p>

                </> : undefined}
                <Button style={{ ...secondaryButton, width: '100%' }} onClick={() => navigate(RECIPE_ADD_LINK)}>
                    <AddIcon />
                    Add Recipe</Button>
            </div>

            {recipes ? <div style={{ width: '90%', width: '90%', maxWidth: '900px', margin: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                {recipes.map((e, i) => <MealCard meal={e.data} key={i} name={e.data.name} size='160px' edit={() => {
                    navigate(RECIPE_EDIT_LINK, { state: { isEdit: true, currentRecipe: e } })  }} />)}
            </div> : <div style={{ width: '100%', textAlign: 'center', margin: '10px' }}><CircularProgress sx={{ margin: 'auto' }} /> </div>}
        </> : undefined
        }

        <div style={{ height: '85px' }}></div>
    </div >
}