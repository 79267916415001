import { getAuth, signOut } from '@firebase/auth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, FormControl, FormLabel, Input } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { MealDayPicker } from '../components/MealDayPicker';
import { MeatSliders } from '../components/MeatSliders';
import { userState } from '../state';
import { secondaryButton } from '../styles/buttons';
import { highlightedTitle } from '../styles/typography';


export const Settings = () => {
    const [user, setUser] = useRecoilState(userState);
    const [settingOpen, setSettingOpen] = useState(undefined)

    const logout = () => {
        signOut(getAuth()).then(e => setUser(undefined)).catch(e => setUser(undefined))
    }

    const getDaysUx = () => {
        return <div style={{ width: '90%', maxWidth: '900px', margin: 'auto' }}>
            <MealDayPicker done={() => setSettingOpen(undefined)} />
        </div>
    }

    const getRestaurantUx = () => {
        return <div style={{ width: '90%', maxWidth: '900px', margin: 'auto' }}>
            <MealDayPicker done={() => setSettingOpen(undefined)} editType="restaurant" />
        </div>
    }

    const getMeatUx = () => {
        return <div style={{ width: '90%', maxWidth: '900px', margin: 'auto' }}>
            <MeatSliders done={() => setSettingOpen(undefined)} />
        </div>
    }

    const optionDivStyle = { display: 'flex', margin: '20px auto', justifyContent: 'space-between' };
    const optionLabelStyle = { margin: 'auto 15px auto 15px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500 };
    return <div style={{ backgroundColor: '#E3E2E0', textAlign: 'center', minHeight: '100vh' }}>
        <div style={{ textAlign: 'center' }}>
            <h1 style={highlightedTitle}>
                Settings
            </h1>
        </div>
        {settingOpen !== undefined ? <div style={{ width: '90%', maxWidth: '900px', textAlign: 'left', margin: 'auto' }}><Button style={{ ...secondaryButton, margin: '0px 0px 0px 15px' }} onClick={() => setSettingOpen(undefined)}>
            <ArrowBackIosIcon />
            Back</Button></div> : undefined}
        {settingOpen === undefined ? <div style={{ textAlign: 'left', width: '90%', maxWidth: '900px', margin: 'auto' }}>
            <h2 style={{ fontFamily: 'Poppins', fontSize: '30px', fontWeight: 500, textAlign: 'left', margin: '0px 15px' }}>Algorithm Options</h2>
            <hr />
            <div style={optionDivStyle}>
                <p style={optionLabelStyle}>Recipe Days: </p>
                <Button onClick={() => setSettingOpen('days')} style={{ ...secondaryButton, margin: '0px 15px' }}>Open</Button>
            </div>
            <div style={optionDivStyle}>
                <p style={optionLabelStyle}>Restaurant Days: </p>
                <Button onClick={() => setSettingOpen('restaurant')} style={{ ...secondaryButton, margin: '0px 15px' }}>Open</Button>
            </div>
            <div style={optionDivStyle}>
                <p style={optionLabelStyle}>Protein Preferences: </p>
                <Button onClick={() => setSettingOpen('meat')} style={{ ...secondaryButton, margin: '0px 15px' }}>Open</Button>
            </div>
            <h2 style={{ fontFamily: 'Poppins', fontSize: '30px', fontWeight: 500, textAlign: 'left', margin: '0px 15px' }}>Account</h2>
            <hr />
            <div style={{ width: '90%', margin: '20px auto', textAlign: 'center' }}>
                <FormControl margin='normal' fullWidth>
                    <FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31' }}>Email</FormLabel>
                    <Input disabled value={user.email}></Input>
                </FormControl>
                <Button style={{ ...secondaryButton, width: '100%', margin: '20px auto' }} onClick={() => logout()}>Log Out</Button></div>
        </div> : undefined}
        {settingOpen === 'days' ? getDaysUx() : undefined}
        {settingOpen === 'restaurant' ? getRestaurantUx() : undefined}
        {settingOpen === 'meat' ? getMeatUx() : undefined}

        <div style={{ height: '100px' }}></div>
    </div>
}