import CoffeeIcon from '@mui/icons-material/Coffee';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import { BottomNavigationAction, CircularProgress, Drawer, FormControl, FormLabel, List, ListItem, ListItemText, Paper, TextField } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getStorage } from 'firebase/storage';
import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import './App.css';
import logo from './Icon.svg';
import { NAV_BAR, ONBOARD_LINK, PLAN_LINK, RECIPE_LINK, RECIPE_ADD_LINK, RECIPE_EDIT_LINK, SETTINGS_LINK, SIDEBAR_NAV } from './links';
import { Onboarding } from './pages/Onboarding';
import { Plan } from './pages/Plan';
import { Settings } from './pages/Settings';
import { Recipes, RecipeEditor } from './Recipes';
import { settingsState, userState } from './state';
import { primaryButton, secondaryButton } from './styles/buttons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { RecipeEditor } from './components/RecipeEditor';


const firebaseConfig = {
  apiKey: "AIzaSyAIDfix6CCNbIIdb2lmV2CYV2kbH6anA9E",
  authDomain: "pland-meal-planning.firebaseapp.com",
  projectId: "pland-meal-planning",
  storageBucket: "pland-meal-planning.appspot.com",
  messagingSenderId: "119320010401",
  appId: "1:119320010401:web:21c9ad56f957c85b73dae0"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);

const functions = getFunctions();
if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', '5001')
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(`
  color: #E3E2E0;
  &.Mui-selected {
    color: #C9882D;
    // background-color: #E3E2E0;
  }
`);

function App() {
  const navigate = useNavigate()
  const location = useLocation();
  const [user, setUser] = useRecoilState(userState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const [createUserFlow, setCreateUserFlow] = React.useState(false);
  const [forgotPasswordFlow, setForgotPasswordFlow] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signInError, setSignInError] = React.useState('');
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (mobile) {
      if (location.pathname !== NAV_BAR[value].link) {
        const index = NAV_BAR.findIndex(e => e.link === location.pathname);
        setValue(Math.max(0, index));
      }
    }
  }, [location, mobile])

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log('auth state changed', user)
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

        setUser({
          uid: user.uid,
          email: user.email,
        })

        await getSettings(user.uid);
      } else {
        // User is signed out
        // ...
      }
      setLoading(false)
    });
  }, [])

  async function getSettings(userId) {
    let settingsDocRef = doc(db, 'users/' + userId + '/details/settings');
    let settingsDocumentSnap = await getDoc(settingsDocRef);

    if (!settingsDocumentSnap.exists()) {
      navigate(ONBOARD_LINK)
    } else {
      setSettings(settingsDocumentSnap.data())
    }
  }

  const signIn = () => {
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        handleError(error)
      });

  }

  const passwordReset = () => {
    setSignInError(undefined)
    sendPasswordResetEmail(auth, username)
      .then(() => {
        console.log('reset email sent!')
        setForgotPasswordFlow(false)
      })
      .catch((error) => {
        handleError(error)
      });
  }

  const createUser = () => {
    createUserWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user)

      })
      .catch((error) => {
        handleError(error)
      });

  }

  const handleError = (error) => {
    if (error.code === 'auth/invalid-email') {
      setSignInError('Invalid Email Address');
    } else if (error.code === 'auth/wrong-password') {
      setSignInError('Incorrect Password');
    } else {
      setSignInError(error.message);
    }
  }

  const navigatedRoutes = ['/plan', '/settings', '/recipes']


  let drawerWidth = navigatedRoutes.includes(location.pathname) ? '200px' : "0px";
  const desktopStyle = { display: 'table', padding: '0px 0px 0px ' + drawerWidth, width: 'calc(100% - ' + drawerWidth + ')', position: 'relative' };
  const mobileStyle = { display: 'table', padding: '0px 0px 0px 0px', width: '100%', position: 'relative', top: '0px' }
  const iconMap = {
    'coffee': <CoffeeIcon fontSize='large' />,
    'calendar': <DateRangeIcon fontSize='large' />,
    'settings': <SettingsIcon fontSize='large' />,
  }

  if (loading) {
    return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#223C49' }}>
      <div style={{ margin: 'auto', width: 'fit-content', top: '50%', left: '50%', position: 'absolute', msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress size={100} sx={{ color: "#C9882D" }} />
      </div>
    </div>
  }


  if (auth.currentUser) {
    return <div style={{ width: '100vw', height: '100vh', fontFamily: 'Poppins; san- serif', top: '-50px', position: 'relative' }}>
      {
        !mobile && navigatedRoutes.includes(location.pathname) ? <Drawer variant="persistent" anchor="left" open={true} PaperProps={{ style: { width: drawerWidth, backgroundColor: '#223C49' } }}>
          <DrawerHeader>
            <div style={{ width: '100%', margin: '20px 0px 0px 12px' }}>
              <img src={logo} style={{ width: '75px', height: '75px', }} alt="logo" />
            </div>
          </DrawerHeader>
          <List>
            {SIDEBAR_NAV.map((text, index) => (
              <ListItem button key={text.name} sx={{ backgroundColor: text.link === location.pathname ? '#CD892D' : undefined }}>
                <ListItemText primary={text.name} onClick={() => navigate(text.link)} sx={{ fontFamily: 'Poppins', color: '#E3E2E0', fontWeight: 700, fontSize: '24px', }} />
              </ListItem>
            ))}
          </List>
        </Drawer> : undefined
      }

      <div style={mobile ? mobileStyle : desktopStyle}>
        <Routes>
          <Route path={RECIPE_LINK} element={<Recipes />} />
          <Route path={RECIPE_EDIT_LINK} element={<RecipeEditor />} />
          <Route path={RECIPE_ADD_LINK} element={<RecipeEditor />} />
          <Route path={SETTINGS_LINK} element={<Settings />} />
          <Route path={PLAN_LINK} element={<Plan />} />
          <Route path={ONBOARD_LINK} element={<Onboarding />} />
          <Route path='*' element={<Navigate to={PLAN_LINK} />} />
        </Routes>
      </div>
      {
        mobile && navigatedRoutes.includes(location.pathname) ? <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation style={{ backgroundColor: '#223C49' }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              navigate(NAV_BAR[newValue].link)
              setValue(newValue);
            }}
          >
            {
              NAV_BAR.map((item, index) => (
                <StyledBottomNavigationAction key={index} icon={iconMap[item.icon]} />
              ))
            }
          </BottomNavigation>
        </Paper> : undefined
      }

    </div >
  }

  return (
    <div style={{ textAlign: 'center', minHeight: '100vh', backgroundColor: '#223C49', display: 'flex', flexDirection: 'column', width: '100%', fontFamily: 'Poppins; san-serif', zIndex: -200 }}>
      <img src={logo} className="App-logo" alt="logo" />
      <h1 style={{ fontSize: 36, color: '#E3E2E0', fontFamily: 'Poppins; san-serif', }}>Perfect Dish</h1>
      {!createUserFlow && !forgotPasswordFlow ? <p style={{ fontSize: 14, marginTop: '0px', color: '#E3E2E0', fontFamily: 'Poppins; san-serif', }}>Unlock your inner meal planning guru.</p> : undefined}
      {createUserFlow ? <p style={{ fontSize: 30, marginTop: '0px', color: '#E3E2E0', fontFamily: 'Poppins; san-serif', }}>Create Account</p> : undefined}
      {forgotPasswordFlow ? <p style={{ fontSize: 30, marginTop: '0px', color: '#E3E2E0', fontFamily: 'Poppins; san-serif', }}>Reset Password</p> : undefined}
      <div style={{ margin: '0px auto', width: '90%', maxWidth: '300px' }}>
        <FormControl margin='dense' fullWidth>
          <FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#E3E2E0', }}>Email</FormLabel>
          <TextField fullWidth placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} sx={{ height: '45px', width: '100%' }} InputProps={{
            style: { border: '#BEB3A9 2px solid', height: '45px', color: '#E3E2E0' }
          }} ></TextField>
        </FormControl>
        {!forgotPasswordFlow ? <FormControl margin='dense' fullWidth><FormLabel sx={{ textAlign: 'left', fontFamily: 'Poppins', fontWeight: '700', color: '#E3E2E0', }}>Password</FormLabel>
          <TextField fullWidth placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} sx={{ height: '45px', width: '100%' }} InputProps={{
            style: { border: '#BEB3A9 2px solid', height: '45px', color: '#E3E2E0' }
          }} ></TextField>
        </FormControl> : undefined}
        {!createUserFlow && !forgotPasswordFlow ? <div style={{ textAlign: 'right', margin: '-12px 0px 0px 0px' }} onClick={() => setForgotPasswordFlow(true)}><p style={{ color: '#BEB3A9' }}>Forgot Password?</p></div> : undefined}
        {signInError ? <div style={{ backgroundColor: '#E3E2E0' }}>Error: {signInError}</div> : undefined}
        {!createUserFlow && !forgotPasswordFlow ? <Button style={{ width: '150px', borderRadius: '10px', backgroundColor: '#CD892D', margin: '15px auto', fontFamily: 'Poppins; san-serif', color: '#302C2F', fontWeight: 'bold', fontSize: 18, fontWeight: 700 }} onClick={() => signIn()}>Login</Button> : undefined}
        {!createUserFlow && !forgotPasswordFlow ? <>
          <p style={{ fontSize: 18, margin: '40px auto 20px auto', fontFamily: 'Poppins', color: '#E3E2E0' }}>No Account Yet?</p>
          <Button style={secondaryButton} onClick={() => setCreateUserFlow(true)}>Create An Account</Button>
        </> : undefined}

        <div style={{ display: 'flex', margin: '10px 0px 50px 0px' }}>
          {createUserFlow ? <Button style={{ ...secondaryButton, width: '100%', margin: '0px 5px 0px 0px' }} onClick={() => setCreateUserFlow(false)}><ArrowBackIosIcon />Back</Button> : undefined}
          {createUserFlow ? <Button style={{ ...primaryButton, width: '100%', margin: '0px 0px 0px 5px' }} onClick={() => createUser()}>Go</Button> : undefined}
          {forgotPasswordFlow ? <Button style={{ ...primaryButton, width: '100%', margin: '0px 0px 0px 5px' }} onClick={() => passwordReset()}>Send Reset Email</Button> : undefined}
        </div>
      </div>
    </div>
  );
}

export default App;
