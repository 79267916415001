
export const SETTINGS_LINK = '/settings';
export const ONBOARD_LINK = '/onboard';
export const PANTRY_LINK = '/pantry';
export const PLAN_LINK = '/plan';
export const RECIPE_LINK = '/recipes';
export const RECIPE_ADD_LINK = '/recipes/add'
export const RECIPE_EDIT_LINK = '/recipes/edit'
export const FOOD_PROFILE_LINK = '/foodprofile';

export const SIDEBAR_NAV = [
    { name: 'Plan', link: '/plan' },
    { name: 'Dishes', link: RECIPE_LINK },
    { name: 'Settings', link: SETTINGS_LINK },
]

export const NAV_BAR = [
    { name: 'Dishes', link: RECIPE_LINK, icon: 'coffee' },
    { name: 'Plan', link: '/plan', icon: 'calendar' },
    { name: 'Settings', link: SETTINGS_LINK, icon: 'settings' },
]
