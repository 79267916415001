import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref } from "firebase/storage";
import React from 'react';
import { useRecoilValue } from "recoil";
import { db, storage } from '../App';
import { userState } from "../state";
import { highlightedTitle } from '../styles/typography';


const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
const MealHeading = styled('h2')(({ theme }) => ({
    paddingTop: theme.spacing(2),
    marginBottom: '5px',
    fontSize: 24,
    fontWeight: 400,
    // [theme.breakpoints.down('sm')]: {
    //     backgroundColor: red[500],
    // },
    // [theme.breakpoints.up('md')]: {
    //     backgroundColor: blue[500],
    // },
    // [theme.breakpoints.up('lg')]: {
    //     backgroundColor: green[500],
    // },
}));

export const MealCard = ({ name, rarity, size = '220px', edit, meal }) => {
    const user = useRecoilValue(userState);
    const [fbImage, setFbImage] = React.useState(undefined)

    React.useEffect(async () => {
        if (meal && meal.imageUuid) {
            let url = await getDownloadURL(ref(storage, `users/${user.uid}/${meal.imageUuid}`))
            setFbImage(url)
        }
    }, [meal])

    return <div style={{
        borderRadius: '10px', width: 'fit-content', margin: '10px auto '
    }}>
        {
            (meal && meal.imageUuid && fbImage) ? <div id={'yes it'} style={{ width: size, height: size, borderRadius: '10px 10px 0px 0px ', backgroundImage: `url(${fbImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div> :
                (meal && meal.imageUuid) ? < div style={{ margin: 'auto', }}>
                    <CircularProgress size={40} sx={{ color: "#C9882D" }} /> </div> :
                    <div id={'not ie'} style={{ width: size, height: size, borderRadius: '10px 10px 0px 0px ', backgroundImage: `url('plate.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>

        }
        <div style={{ backgroundColor: '#EDEDED', width: size, height: '80px', borderRadius: '0px 0px 10px 10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, margin: '0px' }}>{name}</p>
            {/* Status: {rarity} <br /> */}

            {edit ? <EditIcon onClick={() => edit()} /> : undefined}
        </div>
    </div >
}

export const Plan = ({ }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const user = useRecoilValue(userState);
    const functions = getFunctions();
    const [currentPlan, setCurrentPlan] = React.useState(undefined);
    const [currentDay, setCurrentDay] = React.useState(getDayOfWeek(new Date()));
    const [currentWeek, setCurrentWeek] = React.useState(getWeekNumber(new Date())[1]);
    const [isLoading, setIsLoading] = React.useState(false);    

    function getDayOfWeek(d) {
        return (new Date().getDay() || 7) - 1;
    }

    function getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
    }


    React.useEffect(() => {
        if (user) {
            setCurrentPlan(undefined)
            getLatestPlan()
        }
    }, [user, currentWeek])

    const generatePlan = async () => {
        const addMessage = httpsCallable(functions, 'generatePlan');
        setIsLoading(true);
        addMessage({ week: currentWeek })
            .then((result) => {
                throw new Error('error')
                const data = result.data;
                setCurrentPlan(data)
                setIsLoading(false)
            }).catch(error=>{
        console.log(error);
        setIsLoading(false)
           })
    }

    const getDateForIndex = (index) => {
        const currentDate = new Date();
        const currentIndex = getDayOfWeek(currentDate);

        const wkNumber = getWeekNumber(new Date())[1]

        console.log(currentDate.getUTCDate())
        let d = new Date().setUTCDate(currentDate.getUTCDate() - (currentIndex - index) + ((currentWeek - wkNumber) * 7));
        let d2 = new Date(d)
        return (d2.getMonth() + 1) + '/' + d2.getDate() + '/' + d2.getFullYear();
    }

    const getLatestPlan = async () => {
        try {
            let latestPlanQ = query(collection(db, '/users/' + user.uid + '/plans'), where('planWeek', '==', currentWeek), orderBy("generatedAt", 'desc'), limit(1))
            let recipeSnapshot = await getDocs(latestPlanQ);
            if (recipeSnapshot.empty) {
                generatePlan()
            } else {
                recipeSnapshot.forEach((doc) => {
                    //setCurrentPlan(doc.data().weekPlan)
                });
            }
        } catch (e) {
            console.log(e,"e")
        }
    }

    return <div style={{ width: '100%', maxWidth: '100vw', backgroundColor: '#E3E2E0', minHeight: '100vh', padding: '0px' }}>
        <div style={{ textAlign: 'center' }}>
            <h1 style={highlightedTitle}>
                My Plan
            </h1>
        </div>
        <div style={{ width: '90%', maxWidth: '900px', margin: 'auto', display: 'flex', overflowX: 'scroll', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                <ArrowBackIosIcon onClick={(e) => setCurrentWeek(currentWeek - 1)} />
                <div style={{ margin: '4px 5px 5px 0px' }}>Week {currentWeek % 52 + 1}</div>
                <ArrowForwardIosIcon onClick={(e) => setCurrentWeek(currentWeek + 1)} />
            </div>
            <span style={{ display: 'flex' }}>
                {/* <EmailIcon sx={{ width: '35px', height: '35px', margin: '0px 5px' }} /> */}
                <RefreshIcon sx={{ width: '35px', height: '35px', margin: '0px 5px' }} onClick={() => generatePlan()} />
            </span>
        </div>
        <div style={{ width: '90%', maxWidth: '900px', margin: 'auto', display: 'flex', overflowX: 'scroll', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '5px', zIndex: 999 }} >
            {days.map((elem, index) => <div key={index} style={{ minWidth: '125px', margin: '2px 2px', height: '95px', textAlign: 'center', borderRight: '1px solid #F0F0F0' }} onClick={(e) => setCurrentDay(index)}>
                <p style={{ color: '#A5A5A5', fontWeight: 700, fontSize: 16, fontFamily: 'Poppins; san-serif' }}>{elem}</p>
                <p style={{ background: index === currentDay ? 'linear-gradient(180deg,rgba(255,255,255,0) 50%, #C9882D 50%)' : undefined, color: 'black', fontWeight: 700, fontSize: 16, fontFamily: 'Poppins; san-serif', width: 'fit-content', margin: 'auto' }}>
                    {getDateForIndex(index)}
                </p>
            </div>)}
        </div>
    {isLoading && <div style={{ width: '100%', textAlign: 'center', margin: '50px auto' }}><CircularProgress /></div>}
    { !isLoading && !currentPlan && <div style={{ width: '100%', textAlign: 'center', margin: '50px auto'}}>No recipes satisfy this criteria </div> }
        { !isLoading && currentPlan &&
            <div style={{ margin: 'auto', textAlign: 'center', width: '98%', maxWidth: '100%', }}>
                <div style={{ margin: 'auto', width: '90%', maxWidth: '900px', display: 'flex', justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap', maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
                    {currentPlan[currentDay].breakfast ? <div>
                        <><MealHeading>Breakfast</MealHeading>
                            <MealCard name={currentPlan[currentDay].breakfast.name} rarity="Common" meal={currentPlan[currentDay].breakfast} /></>
                    </div> : undefined}
                    {currentPlan[currentDay].lunch ? <div>
                        <><MealHeading>Lunch</MealHeading>
                            <MealCard name={currentPlan[currentDay].lunch.name} rarity="Common" meal={currentPlan[currentDay].lunch} /></>
                    </div> : undefined}
                    {currentPlan[currentDay].dinner ? <div>
                        <><MealHeading>Dinner</MealHeading>
                            <MealCard name={currentPlan[currentDay].dinner.name} rarity="Common" meal={currentPlan[currentDay].dinner} /></>
                    </div> : undefined}
                </div>
                <div style={{ height: '100px' }}></div>
            </div> 
        }


    </div >
}