import { Button, FormControl, FormLabel, Slider } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import React from 'react';
import { useRecoilState } from 'recoil';
import { db } from '../App';
import { settingsState, userState } from '../state';
import { primaryButton } from '../styles/buttons';
const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

export const MealDayPicker = ({ done, editType = "generate" }) => {
    const [user, setUser] = useRecoilState(userState);
    const [settings, setSettings] = useRecoilState(settingsState);

    const unselectedMealStyle = {
        width: '40px',
        height: '40px',
        padding: '0px',
        borderRadius: '25px',
        borderColor: '#C9882D',
        borderWidth: '2px',
        borderStyle: 'solid',
        margin: '5px',
        lineHeight: '2.2',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 18,
        fontWeight: 700,
        color: '#302F31',
    };
    const selectedMealStyle = {
        width: '40px',
        height: '40px',
        padding: '0px',
        borderRadius: '25px',
        borderColor: '#C9882D',
        borderWidth: '2px',
        borderStyle: 'solid',
        backgroundColor: '#C9882D',
        margin: '5px',
        lineHeight: '2.2',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 18,
        fontWeight: 700,
        color: '#302F31',
    };

    function setRestaurantWeekly(numRestaurants) {
        let newSettings = JSON.parse(JSON.stringify(settings));
        newSettings.restaurantsWeekly = numRestaurants
        setSettings(newSettings)
    }

    function toggleDaySettings(i, type, editType) {
        let newSettings = JSON.parse(JSON.stringify(settings));
        newSettings.dayOptions[i][type][editType] = !settings.dayOptions[i][type][editType]
        setSettings(newSettings)
    }

    async function saveSettings() {
        try {
            await setDoc(doc(db, `users/${user.uid}/details/settings`), settings);
            if (done) done()
        } catch (e) {
            console.log(e)
        }
    }

    return <>
        <h3 style={{ fontFamily: 'Poppins', fontSize: '24px', fontWeight: 500 }}>{editType === 'generate' ? 'Generated Meal Days' : 'Restaurant Days'}</h3>
        <h3 style={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 400 }}>{editType === 'generate' ? 'What days and meals would you like a meal to be picked for you?' : 'What days and meals would you like to be a potential restaurant run?'}</h3>
        <hr />
        {
            days.map((day, i) => <div style={{ width: '100%', textAlign: 'center' }} key={i}>
                <p style={{ fontFamily: 'Poppins', color: '#302F31', fontSize: 18, fontWeight: 700, margin: '15px 0px 0px 5px' }}>{day}</p>
                <div style={{ display: 'flex', width: '90%', justifyContent: 'center', margin: 'auto' }}>
                    <div style={settings.dayOptions[i].breakfast[editType] ? selectedMealStyle : unselectedMealStyle}
                        onClick={() => toggleDaySettings(i, 'breakfast', editType)}>
                        B
                    </div>
                    <div style={settings.dayOptions[i].lunch[editType] ? selectedMealStyle : unselectedMealStyle}
                        onClick={() => toggleDaySettings(i, 'lunch', editType)}
                    >
                        L
                    </div>
                    <div style={settings.dayOptions[i].dinner[editType] ? selectedMealStyle : unselectedMealStyle}
                        onClick={() => toggleDaySettings(i, 'dinner', editType)}>
                        D
                    </div>
                </div>
            </div>)
        }
        <div style={{ height: '20px' }}></div>
        {editType === 'restaurant' ?
            <FormControl margin='normal' fullWidth><FormLabel sx={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31', width: '100%', fontSize: '18px' }}>Frequency: {settings.restaurantsWeekly} / 21 meals</FormLabel>
                <Slider max={21} min={0} marks step={1} color='primary' onChangeCommitted={(a, v) => setRestaurantWeekly(v)} value={settings.restaurantsWeekly} />
            </FormControl> : undefined
        }
        <Button style={{ ...primaryButton, margin: '50px auto 20px auto', width: '100%' }} onClick={(e) => saveSettings(e)}>Save</Button>
    </ >
}


