import { Button, FormControl, FormLabel, Slider } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import React from 'react';
import { useRecoilState } from 'recoil';
import { db } from '../App';
import { settingsState, userState } from '../state';
import { primaryButton } from '../styles/buttons';
const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

export const MeatSliders = ({ done }) => {
    const [user, setUser] = useRecoilState(userState);
    const [settings, setSettings] = useRecoilState(settingsState);

    const unselectedMealStyle = {
        width: '40px',
        height: '40px',
        padding: '0px',
        borderRadius: '25px',
        borderColor: '#C9882D',
        borderWidth: '2px',
        borderStyle: 'solid',
        margin: '5px',
        lineHeight: '2.2',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 18,
        fontWeight: 700,
        color: '#302F31',
    };
    const selectedMealStyle = {
        width: '40px',
        height: '40px',
        padding: '0px',
        borderRadius: '25px',
        borderColor: '#C9882D',
        borderWidth: '2px',
        borderStyle: 'solid',
        backgroundColor: '#C9882D',
        margin: '5px',
        lineHeight: '2.2',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 18,
        fontWeight: 700,
        color: '#302F31',
    };

    function setProteinPreference(protein, value) {
        let newSettings = JSON.parse(JSON.stringify(settings));
        if (!newSettings.protein) {
            newSettings.protein = {};
        }
        newSettings.protein[protein.id] = value
        setSettings(newSettings)
    }

    async function saveSettings() {
        try {
            await setDoc(doc(db, `users/${user.uid}/details/settings`), settings);
            if (done) done()
        } catch (e) {
            console.log(e)
        }
    }

    const meats = [
        { label: 'Beef', id: 'beef' },
        { label: 'Chicken', id: 'chicken' },
        { label: 'Turkey', id: 'turkey' },
        { label: 'Pork', id: 'pork' },
        { label: 'Plant-Based', id: 'plant' },
        { label: 'None', id: 'none' },
        { label: 'Other', id: 'other' }
    ]

    return <>
        <h3 style={{ fontFamily: 'Poppins', fontSize: '24px', fontWeight: 500 }}>Protein Frequencies</h3>
        <h3 style={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 400 }}>Adjust your preferred ratio of protein bases.</h3>
        <hr />
        {
            meats.map((meat, i) => <div style={{ width: '100%', textAlign: 'center' }} key={i}>
                <FormControl margin='normal' fullWidth><FormLabel sx={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: '700', color: '#302F31', width: '100%', fontSize: '18px' }}>{meat.label}</FormLabel>
                    <Slider max={4} min={0} marks step={1} color='primary' onChangeCommitted={(a, v) => setProteinPreference(meat, v)} value={settings.protein ? settings.protein[meat.id] : 0} />
                </FormControl>
            </div>)
        }
        <div style={{ height: '20px' }}></div>
        <Button style={{ ...primaryButton, margin: '50px auto 20px auto', width: '100%' }} onClick={(e) => saveSettings(e)}>Save</Button>
    </ >
}


